<script setup>
import {Head} from '@inertiajs/vue3';
import {formatForMoney, getProductThumbnailUrl} from "@Res/js/helpers";
import Icons from "@Components/Icons.vue";
import Header from "./Partials/Header.vue"
import Footer from "./Partials/Footer.vue"
import AnchorLink from "@Components/AnchorLink.vue";
import {onMounted, ref} from "vue";
import clampJs from 'clamp-js';

defineProps({
    store: Object,
    owner: Object,
    products: Array,
});

const productNameRefs = ref([])

const getReviewContent = (average, total) => `${parseFloat(average).toFixed(1)} (${total} ${total > 1 ? 'reviews' : 'review'})`

onMounted(() => {
    productNameRefs.value.forEach((elm) => clampJs(elm, {clamp: 2}))
})
</script>

<template>
    <Head :title="store.name"/>

    <div
        class="bg-white flex flex-col h-screen text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200 min-h-full relative">

        <Header :can-navigate-to-products="false" :store="store" title="Products"/>

        <main class="container flex-grow py-6 lg:pb-14 lg:pt-8 space-y-16 sm:space-y-20 lg:space-y-28">
            <div>
                <div v-if="products.length <= 0" class="py-28">
                    <h1 class="text-gray-600 text-center">No published product</h1>
                </div>

                <div v-else class="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    <div v-for="(product, i) in products" :key="i"
                         class="relative flex flex-col bg-transparent rounded-lg border border-gray-300 hover:shadow-md">
                        <AnchorLink :href="route('storefront.product', {store_slug: store.slug, slug: product.slug})"
                                    class="absolute inset-0"></AnchorLink>

                        <div
                            class="relative flex-shrink-0 p-2 bg-slate-50 bg-opacity-20 dark:bg-slate-300 overflow-hidden z-1 group">
                            <AnchorLink
                                :href="route('storefront.product', {store_slug: store.slug, slug: product.slug})"
                                class="block">

                                <div class="flex w-full">
                                    <img :alt="product.name" :src="getProductThumbnailUrl(product)"
                                         class="w-full md:h-72 lg:h-64 rounded-lg object-cover object-center">
                                </div>
                            </AnchorLink>

                            <div
                                class="rounded-full flex items-center  space-x-1.5 absolute top-4 left-4 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 text-slate-700 dark:text-slate-300">
                                <svg class="size-3">
                                    <use :href="`#icon-${product.type}`"/>
                                </svg>
                                <span class="leading-none" v-text="product.type.replace('_', ' ')"/>
                            </div>
                        </div>

                        <div class="divide-y divide-gray-300">
                            <div class="px-3 py-2 h-16">
                                <h6 ref="productNameRefs" class="font-normal leading-5 transition-colors"
                                    v-text="product.name"/>
                            </div>

                            <div class="flex justify-between items-end px-3 py-2">
                                <span class="text-sm" v-text="formatForMoney(product.price, store.currency)"/>

                                <div class="flex items-center mb-0.5">
                                    <svg class="size-4 pb-[1px] fill-amber-400">
                                        <use href="#icon-star"/>
                                    </svg>
                                    <span class="text-xs ml-1 text-slate-800 dark:text-slate-400"
                                          v-text="product.totalRatings > 0 ? getReviewContent(product.averageRating, product.totalRatings) : '0' "/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Pagination Here -->
            </div>
        </main>

        <Footer/>
    </div>

    <Icons/>
</template>
